import axios from 'axios';
import { tracer } from '../tracing';

const DEV_URI = '/api';
const BASE_URI = process.env.NODE_ENV === 'development'
  ? DEV_URI : process.env.REACT_APP_BACKEND;
// console.log(BASE_URI);

export const client = axios.create({
  baseURL: BASE_URI,
  timeout: 90000,
});

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}

client.interceptors.request.use(config => {
  // Get the CSRF token from the cookies
  const csrfToken = getCookie('csrftoken');
  
  if (csrfToken) {
    // Add the CSRF token to the request headers
    config.headers['X-CSRFToken'] = csrfToken;
  }
  
  return config;
}, error => {
  // Handle the error
  return Promise.reject(error);
});

client.interceptors.request.use(request => {
  const span = tracer.startSpan('axios_request', {
    attributes: {
      'http.method': request.method,
      'http.url': request.url,
    },
  });

  request.headers['traceparent'] = span.spanContext().traceId;
  (request).span = span;

  return request;
}, error => {
  console.error('Request error:', error);
  return Promise.reject(error);
});

client.interceptors.response.use(response => {
  const span = (response.config).span;
  span.setAttribute('http.status_code', response.status);
  span.end();
  return response;
}, error => {
  if (error.config && error.config.span) {
    const span = error.config.span;
    span.setAttribute('http.status_code', error.response?.status);
    span.setAttribute('error', true);
    span.end();
  }
  return Promise.reject(error);
});

client.interceptors.request.use(request => {
  const span = tracer.startSpan('axios_request', {
    attributes: {
      'http.method': request.method,
      'http.url': request.url,
    },
  });

  request.headers['traceparent'] = span.spanContext().traceId;
  (request).span = span;

  return request;
}, error => {
  console.error('Request error:', error);
  return Promise.reject(error);
});

client.interceptors.response.use(response => {
  const span = (response.config).span;
  span.setAttribute('http.status_code', response.status);
  span.end();
  return response;
}, error => {
  if (error.config && error.config.span) {
    const span = error.config.span;
    span.setAttribute('http.status_code', error.response?.status);
    span.setAttribute('error', true);
    span.end();
  }
  return Promise.reject(error);
});

const AuthManager = () => {
  let token = null;
  let apiKey = null;
  const logoutEventName = 'avery-logout';
  const refreshName = 'avery-refresh';
  // let isRefreshing = null;

  // console.log(token);

  const getToken = () => {
    if (token === null) {
      const storedToken = localStorage.getItem(refreshName);
      if (storedToken === 'null') return null;
      token = storedToken;
    }
    return token;
  };

  const setToken = (key) => {
    token = key;
    localStorage.setItem(refreshName, key);
  };

  const ereaseToken = () => {
    token = null;
    localStorage.setItem(logoutEventName, Date.now());
    localStorage.setItem(refreshName, null);
  };

  const setApiKey = (key) => {
    apiKey = key;
    localStorage.setItem('Api-Key', key);
  };

  const eraseApiKey = () => {
    apiKey = null;
    localStorage.setItem('Api-Key', null);
  };

  const getApiKey = () => {
    if (apiKey === null) {
      const storedApiKey = localStorage.getItem('Api-Key');
      if (storedApiKey === 'null') return null;
      apiKey = storedApiKey;
    }
    return apiKey;
  };

  // This listener allows to disconnect another session started in another tab
  window.addEventListener('storage', (event) => {
    if (event.key === logoutEventName) {
      token = null;
    }
  });

  return {
    BASE_URI,
    getToken,
    setToken,
    ereaseToken,
    getApiKey,
    setApiKey,
    eraseApiKey,
  };
};

export default AuthManager;
