import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';

import ValidatedForm, { FloatInput } from 'components/Form/Inputs';
import ImageCropper from 'components/Form/Image';
import { Admin, Manager, Sales } from './Permissions';
// import styles from './UserSetup.module.css';

const UserSetup = ({ id, endpoint, refresh }) => {
  const empty = {
    first_name: '',
    last_name: '',
    email: '',
    role: 'Solar Advisor',
  };
  const [user, setUser] = useState(empty);
  const [crop, setCrop] = useState({ show: false });

  const setRole = (e) => {
    setUser({ ...user, role: e.target.id });
  };

  const handleClose = () => setCrop({ show: false });

  const handleCrop = async (cropped) => {
    setCrop({ show: false, image: cropped });

    // prepare form data for submission
    const form = new FormData();
    const blob = await fetch(cropped).then((r) => r.blob());
    form.append('profile', blob, 'profile.jpg');
    endpoint.update(user.id, form).then((data) => setUser(data));
  };

  const handleProfile = (e) => {
    if (!e.target.files[0]) {
      setCrop({ show: false, image: undefined });
      return;
    }
    // set image from file
    setCrop({ show: true, image: URL.createObjectURL(e.target.files[0]) });
  };

  const saveCreate = () => {
    const { profile, ...userData } = user;
    if (id) {
      console.log('Updating staff member');
      return endpoint.update(id, userData).then((data) => {
        setUser(data);
        refresh();
      });
    }
    return endpoint.create(userData).then(
      () => {
        console.log('success');
        refresh();
      },
    );
  };

  useEffect(() => {
    if (id && user.id === undefined) {
      endpoint.retrieve(id).then((data) => setUser(data));
    }
  }, [id, user, endpoint]);

  return (
    <ValidatedForm onSave={saveCreate}>
      <Row className="w-100">
        <Col sm={3} className="text-center">
          <ImageCropper
            aspect={1}
            show={crop.show}
            image={crop.image ? crop.image : user.profile}
            onClose={handleClose}
            onCrop={handleCrop}
          />
          <div
            role="button"
            tabIndex={-1}
            className="mx-auto rounded-circle position-relative overflow-hidden"
            style={{ width: 110, height: 110 }}
            onClick={() => document.getElementById('user-profile').click()}
            onKeyDown={() => {}} // make warnings stop
          >
            <img src={user.profile || '/profile.png'} height="100%" alt="logo" />
            <Button className="position-absolute fixed-top w-100 p-0 opacity-75">
              <PersonCircle size={90} color="white" />
            </Button>
          </div>
          <Form.File
            id="user-profile"
            className="d-none"
            onChange={handleProfile}
          />
        </Col>
        <Col sm={9} className="mt-2">
          <Row xs={1} sm={2}>
            <Col>
              <FloatInput
                label="First Name"
                value={user.first_name}
                onChange={(e) => setUser({ ...user, first_name: e.target.value })}
                // disabled={user.id}
              />
            </Col>
            <Col>
              <FloatInput
                label="Last Name"
                value={user.last_name}
                onChange={(e) => setUser({ ...user, last_name: e.target.value })}
                // disabled={user.id}
              />
            </Col>
          </Row>

          <Row xs={1} sm={2} className="my-2">
            <Col>
              <FloatInput
                required
                label="Email"
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                disabled={user.id}
              />
            </Col>
            <Col>
              <FloatInput
                label="Phone"
                value={user.phone}
                onChange={(e) => setUser({ ...user, phone: e.target.value })}
                // disabled={user.id}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <hr />

      <h6 className="font-weight-bold">User Type</h6>
      <Row>
        <Col>
          <Form.Check
            id="Admin"
            name="userRole"
            type="radio"
            label="Company Admin"
            checked={user.role === 'Admin'}
            onChange={setRole}
            style={{ display: 'inline' }}
          />
          <Admin />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            id="Solar Advisor"
            name="userRole"
            type="radio"
            label="Solar Advisor"
            checked={user.role === 'Solar Advisor'}
            onChange={setRole}
            style={{ display: 'inline' }}
          />
          <Sales />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            id="Project Manager"
            name="userRole"
            type="radio"
            label="Project Manager"
            checked={user.role === 'Project Manager'}
            onChange={setRole}
            style={{ display: 'inline' }}
            disabled
          />
          <Manager />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <FloatInput
            type="number"
            label="Comission (per Watt)"
            prepend="$"
            value={user.commission}
            onChange={(e) => setUser({ ...user, commission: e.target.value })}
          />
        </Col>
        <Col>
          <FloatInput
            type="number"
            label="Max. Discount (per Watt)"
            prepend="$"
            value={user.discount}
            onChange={(e) => setUser({ ...user, discount: e.target.value })}
          />
        </Col>
      </Row>

      <Row className="mt-3 d-flex justify-content-end">
        <Col sm={2}>
          <Button type="submit" className="w-100" disabled={!user.email}>
            {user.id ? 'Save' : 'Create'}
          </Button>
        </Col>
      </Row>
    </ValidatedForm>
  );
};

// TODO: propTypes

export default UserSetup;
