import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, InputGroup, Form, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { Trash, CheckCircle, XCircle } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const Panels = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('panels/');
  const panelsEndpoint = newEndpoint('hardware/panels/');
  const manufacturersEndpoint = newEndpoint('hardware/manufacturers');

  const [manufacturers, setManufacturers] = useState([]);
  const [panelManufacturer, setPanelManufacturer] = useState();
  const [panels, setPanels] = useState([]);
  const [panelModels, setPanelModels] = useState([]);
  const [newPanel, setNewPanel] = useState();
  const [spinner, setSpinner] = useState(false);
  // const [selectedPanel, setSelectedPanel] = useState();

  useEffect(() => {
    // get all available manufacturers and company's configured panels
    manufacturersEndpoint.list().then((data) => {
      const filteredData = data.filter((item) => item.product === 'PAN' || item.product === 'PAN_INV').sort((a, b) => a.name.localeCompare(b.name));
      setManufacturers(filteredData);
    });
    panelsEndpoint.list().then((data) => setPanels(data));
  }, []);

  useEffect(() => {
    // get all panel models of manufacturer
    if (panelManufacturer) {
      setSpinner(true);
      endpoint.list(`?manufacturer=${panelManufacturer}`).then((data) => {
        setPanelModels(data.sort((a, b) => a.model.localeCompare(b.model)));
        setSpinner(false);
      });
    }
  }, [panelManufacturer]);

  const handleUpdate = (panel) => {
    panelsEndpoint.update(panel.id, panel)
      .then(() => displaySnack({ variant: 'success', message: `Updated ${panel.model}.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${panel.model}.` }));
  };

  // handlers for newPanel
  const handleManufacturerChange = (e) => {
    const manufacturer = e[0]?.name;
    const { cost } = Object(newPanel);
    setPanelManufacturer(manufacturer);
    setNewPanel({ cost, manufacturer });
  };

  const handleModelChange = (e) => {
    // const panel = panelModels.find((item) => item.model === e.target.value);
    const panel = e[0];
    const { manufacturer, cost } = newPanel;
    setNewPanel({ ...panel, cost, manufacturer });
  };

  const additionalCostChangeHandler = (e) => {
    setNewPanel({ ...newPanel, cost: e.target.value });
  };

  const handleUpdateCost = (value, index) => {
    const updated = Array.from(panels);
    updated[index].cost = value;
    setPanels(updated);
  };

  const handleDelete = (index) => {
    const updated = Array.from(panels);
    const rm = updated.splice(index, 1)[0];
    panelsEndpoint.remove(rm.id)
      .then(() => { setPanels(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.model}` }));
  };

  const handleCreate = () => {
    // send POST request for new panel
    const { id, cost } = newPanel;
    panelsEndpoint.create({ panel: id, cost })
      .then(() => panelsEndpoint.list().then((data) => setPanels(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newPanel.model}.` });
        setNewPanel();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add new panel.',
      }));
  };

  return (
    <>
      <Row className="font-weight-bold mt-2">
        <Col sm={3}>Panel Manufacturer</Col>
        <Col sm={3}>Model</Col>
        <Col sm={2}>Cost per watt</Col>
        <Col sm={2}>Built-in Inverter</Col>
      </Row>

      {panels.map((item, index) => (
        <Row key={item.id} className="mt-2">
          <Col sm={3} className="pt-2">{item.manufacturer?.name}</Col>
          <Col sm={3} className="pt-2">{item.model}</Col>
          <Col sm={2}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                value={item.cost}
                onChange={(e) => handleUpdateCost(e.target.value, index)}
              />
            </InputGroup>
          </Col>
          <Col sm={2} className="pt-2">
            {item.has_micro ? <CheckCircle size={20} /> : <XCircle size={20} />}
          </Col>
          <Col sm={2}>
            <ButtonGroup size="sm">
              <Button variant="outline-primary p-2" onClick={() => handleUpdate(item)}>
                <Save />
              </Button>
              <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                <Trash />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      ))}

      {/* Additional Panel */}
      {newPanel === undefined ? (
        <Row className="mt-4">
          <Col>
            <Button onClick={() => setNewPanel({})}>+ Add Panels</Button>
          </Col>
        </Row>
      ) : (
        <ValidationForm onSave={handleCreate}>
          <Row className="mt-3">
            <Col sm={3}>
              {/* <Form.Control
                required
                as="select"
                value={newPanel.manufacturer || ''}
                onChange={handleManufacturerChange}
              >
                <option disabled value="">Choose Manufacturer</option>
                {manufacturers.map((manf) => (
                  <option key={manf.name} value={manf.name}>{manf.name}</option>
                ))}
              </Form.Control> */}
              <Typeahead
                id="basic-typeahead-manufacturers"
                labelKey="name"
                onChange={handleManufacturerChange}
                options={manufacturers}
                placeholder="Choose Manufacturer"
              />
            </Col>
            <Col sm={3}>
              {spinner ? (
                <Spinner animation="border" />
              ) : (
                // <Form.Control
                //   required
                //   as="select"
                //   onChange={handleModelChange}
                //   value={newPanel.model || ''}
                // >
                //   <option disabled value="">Choose Model</option>
                //   {newPanel?.manufacturer && panelModels
                //     .filter(({ manufacturer }) => manufacturer?.name === newPanel.manufacturer)
                //     .map(({ id, model }) => (
                //       <option key={id} value={model}>{model}</option>
                //     ))}
                // </Form.Control>
                <Typeahead
                  id="basic-typeahead-panels"
                  labelKey="model"
                  onChange={handleModelChange}
                  options={panelModels
                    .filter(({ manufacturer }) => manufacturer?.name === newPanel?.manufacturer)}
                  placeholder="Choose Model"
                />
              )}
            </Col>
            <Col sm={2}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  type="number"
                  onChange={additionalCostChangeHandler}
                  value={newPanel.cost || ''}
                  name="panel_additional_cost"
                />
              </InputGroup>
            </Col>
            <Col sm={2} className="pt-2">
              {newPanel.has_micro ? <CheckCircle size={20} /> : <XCircle size={20} />}
            </Col>
            <Col sm={2}>
              <Button variant="outline-primary p-2" size="sm" onClick={() => setNewPanel()}>
                <Trash />
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit">Save</Button>
            </Col>
          </Row>
        </ValidationForm>
      )}
    </>
  );
};

export default Panels;
